<template>
  <div class="main-manager-employee">
    <b-card>
      <validation-observer ref="userRequestDTO">
        <b-row class="mb-2">
          <b-col md="6" sm="6" xs="6">
            <h4>{{ isUpdate ? 'Cập nhật tài khoản' : 'Tạo mới tài khoản' }}</h4>
          </b-col>
          <b-col md="6" sm="6" xs="6">
            <b-button
                v-if="isUpdate"
                class="float-right"
                variant="outline-warning"
                @click="$refs['reset-password-modal'].show()"
            >
              <feather-icon
                  icon="UnlockIcon"
                  class="mr-50"
              />
              <span class="align-middle">Đặt lại mật khẩu</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              lg="3"
              md="3"
              sm="3"
              xs="12"
          >
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required|max:300"
              >
                <label
                    class="font-weight-bold font-size-label-custom"
                    for="userNameId"
                >Tên đăng nhập <span class="text-danger">(*)</span></label>
                <b-form-input
                    id="userNameId"
                    v-model="userRequestDTO.username"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    placeholder="Nhập tên đăng nhập"
                    :disabled="isUpdate"
                    @keyup="onChangeValidate(userRequestDTO.username)"
                />
                <small
                    v-if="isValidateUserName === true"
                    class="text-danger"
                >Tên đăng nhập chỉ chứa các kí tự cho phép gồm: chữ in hoa, chữ in thường, chữ số (a-z, A-Z, 0-9), dấu
                  gạch dưới. </small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
              lg="3"
              md="3"
              sm="3"
              xs="12"
          >
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="Tên tài khoản"
                  rules="required|max:300"
              >
                <label
                    class="font-weight-bold font-size-label-custom"
                    for="userNameId"
                >Tên tài khoản<span class="text-danger">(*)</span></label>
                <b-form-input
                    v-model="userRequestDTO.name"
                    class="text-input-form"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    placeholder="Nhập tên tài khoản"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3 col-12">
            <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email|required"
            >
              <b-form-group>
                <label
                    class="font-weight-bold font-size-label-custom"
                    for="email"
                >Email<span class="text-danger">(*)</span></label>
                <b-form-input
                    id="email"
                    v-model="userRequestDTO.email"
                    :state="errors.length > 0 ? false:null"
                    type="text"
                    class="form-control"
                    placeholder="Nhập email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12">
            <label class="font-weight-bold font-size-label-custom">Danh sách chức năng</label>
            <b-form-group>
              <b-form-checkbox
                  v-for="option in listAllMenu"
                  :key="option.id"
                  v-model="selected"
                  :value="option.id"
                  name="flavour-3a"
                  :class="'checkBoxAccount option' + option.id"
              >
                {{ option.title }}
                <b-form-group
                    v-if="check && option.id === 2"
                    class="radioCheckBoxAccount"
                >
                  <!--                <b-form-radio-group-->
                  <!--                  v-model="selectedSchoollevel"-->
                  <!--                  :options="listSchoollevel"-->
                  <!--                  name="radios-stacked"-->
                  <!--                  stacked-->
                  <!--                />-->
                  <b-form-checkbox-group
                      v-model="selectedSchoollevel"
                      :options="listSchoollevel"
                      value-field="value"
                      text-field="text"
                      stacked
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <div class="acction-form-input-employee">
              <b-button
                  v-if="!isUpdate"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mr-2"
                  variant="outline-primary"
                  @click="addAccount"
              >
                <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                />
                <span class="align-middle">Tạo mới</span>
              </b-button>
              <b-button
                  v-if="isUpdate"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mr-2"
                  variant="outline-primary"
                  @click="updateAccount"
              >
                <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                />
                <span class="align-middle">Cập nhật</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning"
                  @click="goBack"
              >
                <feather-icon
                    icon="CornerDownLeftIcon"
                    class="mr-50"
                />
                <span class="align-middle">Quay lại</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
      <b-modal ref="reset-password-modal" hide-footer title="Đặt lại mật khẩu">
        <div class="d-block text-center">
          <span class="font-medium-2">Bạn có <span class="text-warning">chắc chắn</span> muốn <span
              class="text-warning">đặt lại mật khẩu</span> cho tài khoản này?</span>
        </div>
        <div class="float-right mt-2">
          <b-button class="mr-2" variant="success" @click="resetPassword">
            <feather-icon
                icon="CheckIcon"
                class="mr-50"
            />
            Đồng ý
          </b-button>
          <b-button variant="outline-danger" @click="$refs['reset-password-modal'].hide()">
            <feather-icon
                icon="XIcon"
                class="mr-50"
            />
            Hủy
          </b-button>
        </div>
      </b-modal>
      <b-modal id="new-password-modal" hide-footer>Hello From My Modal!</b-modal>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCol, BFormGroup, BFormInput, BRow, BFormCheckbox, BFormRadioGroup, BButton, BFormCheckboxGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import {heightTransition} from '@core/mixins/ui/transition'
import useJwt from '@/auth/jwt/useJwt'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BButton,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      idAccount: null,
      userRequestDTO: {
        username: null,
        name: null,
        email: null,
        userManagementRequestDTOList: [],
      },
      selected: [],
      selectedSchoollevel: [],
      listAllMenu: [],
      listAllMenuStatic: [],
      listSchoollevel: [
        {value: 1, text: 'Cấp Tiểu học'},
        {value: 2, text: 'Cấp THCS'},
        {value: 3, text: 'Cấp THPT'},
        {value: 4, text: 'Mầm non'},
      ],
      check: false,
      isUpdate: false,
      isValidateUserName: false,
      isValidateName: false,
    }
  },
  computed: {},
  watch: {
    selected(value) {
      if (value.find(x => x === 2)) {
        this.check = true
      } else {
        this.selectedSchoollevel = []
        this.check = false
      }
    },
  },
  created() {
    const parameters = this.$route.query
    this.idAccount = Number(parameters.id)
    if (this.idAccount) {
      this.isUpdate = true
      this.getDetailAccount()
    }
    this.getAllListMenu()
  },
  methods: {
    getDetailAccount() {
      this.$crm.post(`user/find-by-id/${this.idAccount}`).then(res => {
        this.userRequestDTO = res.data
        this.listAllMenu = this.listAllMenuStatic
        this.selected = res.data.userManagementRequestDTOList.map(l => l.urlId)
        res.data.userManagementRequestDTOList.map(m => {
          if (m.level.length > 0) {
            this.check = true
            this.selectedSchoollevel = m.level
          }
        })
      })
    },
    getAllListMenu() {
      this.$crm.post('user/find-all-menu').then(res => {
        this.listAllMenu = res.data
        this.listAllMenuStatic = res.data
      })
    },
    addAccount() {
      this.$refs.userRequestDTO.validate().then(success => {
        if (success) {
          if (this.selected.length === 0) {
            return this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Lỗi',
                icon: 'XIcon',
                variant: 'danger',
                text: 'Tài khoản phải được cấp quyền ít nhất 1 chức năng!',
              },
            })
          } else {
            let data = this.selected.find(x => x === 2)
            if (data && this.selectedSchoollevel.length === 0) {
              return this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Lỗi',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: 'Phải lựa chọn ít nhất 1 cấp học!',
                },
              })
            }
            this.selected.map(x => {
              this.userRequestDTO.userManagementRequestDTOList.push({
                level: (this.selectedSchoollevel.length > 0 && x === 2) ? this.selectedSchoollevel : [],
                urlId: x,
                userId: null,
              })
            })
            this.userRequestDTO.userManagementRequestDTOList.sort((a, b) => a.urlId - b.urlId)
          }
          this.$crm.post('user/create', this.userRequestDTO).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tạo tài khoản thành công',
              },
            })
            this.$router.push({
              name: 'manager-account',
            })
          })
        }
      })
    },
    updateAccount() {
      this.$refs.userRequestDTO.validate().then(success => {
        if (success) {
          if (this.selected.length === 0) {
            return this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Lỗi',
                icon: 'XIcon',
                variant: 'danger',
                text: 'Tài khoản phải được cấp quyền ít nhất 1 chức năng!',
              },
            })
          } else {
            let data = this.selected.find(x => x === 2)
            if (data && this.selectedSchoollevel.length === 0) {
              return this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Lỗi',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: 'Phải lựa chọn ít nhất 1 cấp học!',
                },
              })
            }
            this.userRequestDTO.userManagementRequestDTOList = []
            this.selected.map(x => {
              this.userRequestDTO.userManagementRequestDTOList.push({
                level: (this.selectedSchoollevel.length > 0 && x === 2) ? this.selectedSchoollevel : [],
                urlId: x,
                userId: null,
              })
            })
            this.userRequestDTO.userManagementRequestDTOList.sort((a, b) => a.urlId - b.urlId)
          }

          this.$crm.post('user/update', this.userRequestDTO).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Cập nhật tài khoản thành công',
              },
            })
            this.$router.push({
              name: 'manager-account',
            })
          })
        }
      })
    },
    goBack() {
      this.$router.push('/manager-account')
    },
    // Validate
    onChangeValidate(value) {
      if (value) {
        const validateUsername = /^[A-Za-z0-9_]+$/
        if (validateUsername.test(value) === false) {
          this.isValidateUserName = true
        } else {
          this.isValidateUserName = false
        }
      } else {
        this.isValidateUserName = false
      }
    },

    resetPassword() {
      this.$authUser.post(`api/v1/user/reset-password-by-username?username=${this.userRequestDTO.username}&password=${'admin123456'}`).then(() => {
        this.$refs['reset-password-modal'].hide()
        this.$bvToast.toast(`Đã đặt lại mật khẩu về mật khẩu mặc định 'admin123456'`, {
          title: 'Thành công',
          variant: 'success',
          solid: true,
          noCloseButton: true,
          autoHideDelay: 5000,
        })
      }).catch(() => {
        this.$refs['reset-password-modal'].hide()
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/custom/manger-employee';
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-sweetalert.scss';

.checkBoxAccount {
  margin-bottom: 5px;
}

.radioCheckBoxAccount {
  margin-top: 5px;
  margin-bottom: 5px;
}

</style>
